<template>
    <!-- <v-navigation-drawer v-model="drawer" :rail="rail" permanent @click="rail = false" @mouseenter="expandDrawer"
        @mouseleave="collapseDrawer"> -->
    <v-navigation-drawer v-model="drawer" :permanent="true" :width="180">
        <v-divider></v-divider>
        <v-list density="compact" nav class="left-align-text">
            <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-pirate" title="Scraper"
                :to="{ name: 'home' }" value="patients"></v-list-item>
            <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-account-switch" title="Leads"
                :to="{ name: 'leads' }" value="leads"></v-list-item>
            <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-email" title="Emails"
                :to="{ name: 'emails' }" value="emails"></v-list-item>
            <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-nintendo-game-boy"
                title="Playground" :to="{ name: 'playground' }" value="playground"></v-list-item>
            <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-account" title="Users"
                :to="{ name: 'users' }" value="users"></v-list-item>
            <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-hospital-building"
                title="Clinics" :to="{ name: 'clinics' }" value="clinics"></v-list-item>
            <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-file-document"
                title="Templates" :to="{ name: 'templates' }" value="templates"></v-list-item>
            <v-list-item :class="{ 'no-underline-on-hover': true }" link prepend-icon="mdi-logout" title="Logout"
                value="logout" @click="logout"></v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>


import { getAuth, signOut } from "firebase/auth";
import { useRouter } from 'vue-router'

export default {
    data() {
        return {
            drawer: true,
            rail: true,
        }
    },
    methods: {
        expandDrawer() {
            this.rail = false;
        },
        collapseDrawer() {
            this.rail = true;
        },

    },
    setup() {
        const auth = getAuth();
        const router = useRouter()

        const logout = async () => {
            console.log("logout")

            await signOut(auth)
            router.push({ name: 'auth' })
        }
        return { logout }
    },

}
</script>

<style scoped>
.no-underline-on-hover:hover {
    text-decoration: none !important;
}

.left-align-text {
    text-align: left;
}
</style>