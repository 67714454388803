<template>
  <v-container>
    <!-- Search Fields -->
    <v-row>
      <!-- Search by Template ID -->
      <v-col cols="12" sm="6" md="4">
        <v-text-field 
          v-model="templateSearchQuery" 
          label="Search by Template ID" 
          outlined 
          dense 
          clearable
          dark
          class="dark-field"
          @keyup.enter="searchTemplate">
          <template v-slot:append>
            <v-btn color="primary" @click="searchTemplate" :loading="searchLoading">
              Search
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <!-- Search by User ID or Email -->
      <v-col cols="12" sm="6" md="4">
        <v-text-field 
          v-model="userSearchQuery" 
          label="Search by User ID or Email" 
          outlined 
          dense 
          clearable
          dark
          class="dark-field"
          @keyup.enter="searchUser">
          <template v-slot:append>
            <v-btn color="primary" @click="searchUser" :loading="searchLoading">
              Search
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <!-- Detailed Template View (when searched by Template ID) -->
    <v-row v-if="selectedTemplate">
      <v-col cols="12">
        <v-card class="template-details-card" dark>
          <v-card-title class="text-h5 font-weight-bold white--text">
            <v-icon color="white" class="mr-4">mdi-file-document</v-icon>
            <!-- Template Header Input -->
            <v-text-field 
              v-model="selectedTemplate.templateHeader"
              label="Template Header"
              outlined 
              dense
              class="dark-field"
            />
            <!-- Save Template Header Button -->
            <v-btn 
              color="success" 
              @click="saveTemplateHeader" 
              :loading="headerLoading"
              class="ml-2"
            >
              Save
            </v-btn>
          </v-card-title>
          
          <v-divider dark></v-divider>

          <v-card-text>
            <!-- Metadata -->
            <v-row>
              <!-- First Column -->
              <v-col cols="12" md="6">
                <v-list dense dark>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="white">mdi-identifier</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle class="white--text">Template ID</v-list-item-subtitle>
                      <v-list-item-title class="white--text">
                        {{ selectedTemplate.templateId }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="white">mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle class="white--text">User</v-list-item-subtitle>
                      <v-list-item-title class="white--text">
                        {{ selectedTemplate.userName || 'Unknown User' }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <!-- Second Column -->
              <v-col cols="12" md="6">
                <v-list dense dark>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="white">mdi-calendar</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle class="white--text">Created At</v-list-item-subtitle>
                      <v-list-item-title class="white--text">
                        {{ formatDate(selectedTemplate.createdAt) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="white">mdi-update</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle class="white--text">Last Updated</v-list-item-subtitle>
                      <v-list-item-title class="white--text">
                        {{ formatDate(selectedTemplate.updatedAt) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <!-- Template Sections (detailed view) -->
            <v-row>
              <v-col cols="12">
                <v-expansion-panels dark>
                  <v-expansion-panel
                    v-for="section in sortedSections"
                    :key="section.originalName"
                    class="dark-panel"
                  >
                    <v-expansion-panel-header class="text-h6 white--text">
                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-text-field
                            v-model="section.name"
                            label="Section Name"
                            outlined
                            dense
                            class="dark-field"
                          />
                        </v-col>
                        <v-col cols="6" class="text-right">
                          <v-chip small color="primary" class="mr-2" dark>
                            Order: {{ section.order }}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card-text>
                        <!-- Dropdown for Section Style -->
                        <v-select 
                          v-model="section.style"
                          :items="styleOptions"
                          label="Display Style"
                          outlined
                          dense
                          class="dark-field mb-4"
                        />
                        <!-- Instructions -->
                        <div class="section-detail mb-4">
                          <div class="white--text font-weight-bold mb-2">Instructions:</div>
                          <v-textarea 
                            v-model="section.dlevel" 
                            outlined 
                            dense
                            label="Instructions"
                            class="dark-field"
                            :rows="4"
                          />
                        </div>
                        <!-- Save Section Button -->
                        <v-btn 
                          color="success" 
                          @click="saveSection(section)"
                          :loading="section.loading"
                          class="mt-2"
                        >
                          Save Section
                        </v-btn>
                      </v-card-text>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- List of Templates for the Searched User -->
      <v-row v-if="userTemplates.length > 0" justify="start">
        <v-col cols="12" sm="8" md="6">
          <v-card class="user-templates-card text-left" dark>
            <v-card-title class="white--text text-left">
              Templates for User
            </v-card-title>
            <v-divider dark></v-divider>
            <v-list dense class="text-left">
              <v-list-item 
                v-for="template in userTemplates" 
                :key="template.templateId"
                class="text-left"
              >
                <v-list-item-content class="text-left">
                  <v-list-item-title class="white--text text-left">
                    {{ template.templateHeader }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="white--text text-left">
                    ID: {{ template.templateId }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

  </v-container>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { db } from '@/firebase';
import firebase from 'firebase/compat/app'; // For FieldValue.delete()

const { FieldValue } = firebase.firestore;

// Reactive search fields and state variables
const templateSearchQuery = ref('');
const userSearchQuery = ref('');
const selectedTemplate = ref(null);
const userTemplates = ref([]); // For storing all templates for the searched user
const searchLoading = ref(false);
const headerLoading = ref(false);
const error = ref(null);
const noResults = ref(false);

// Reactive array for sections (detailed view for a single template)
const sections = ref([]);

// Dropdown options for section style
const styleOptions = ["Bullet Points", "Flow Text"];

// Computed property to sort sections by order
const sortedSections = computed(() =>
  sections.value.slice().sort((a, b) => a.order - b.order)
);

// Update local sections from the selected template's items
const updateSections = () => {
  if (selectedTemplate.value && selectedTemplate.value.items) {
    sections.value = Object.entries(selectedTemplate.value.items).map(([key, data]) => ({
      originalName: key,  // Original Firestore key
      name: key,          // Editable section name
      order: data.order || 0,
      style: data.style || "Bullet Points", // Default to "Bullet Points"
      dlevel: data.dlevel || '',
      loading: false      // For per-section loading state
    }));
  }
};

// Watch for changes to the selected template to update sections automatically
watch(selectedTemplate, (newVal) => {
  if (newVal && newVal.items) updateSections();
});

// Helper to determine if a text is an email
const isEmail = (text) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(text);
};

// Search for a template by Template ID (detailed view)
const performSearch = async (queryCallback) => {
  searchLoading.value = true;
  error.value = null;
  noResults.value = false;
  selectedTemplate.value = null;
  try {
    const result = await queryCallback();
    if (!result.exists && !result.docs) {
      noResults.value = true;
      return;
    }
    if (result.exists) {
      selectedTemplate.value = { ...result.data(), templateId: result.id };
    } else if (result.docs && result.docs.length > 0) {
      const doc = result.docs[0];
      selectedTemplate.value = { ...doc.data(), templateId: doc.id };
    } else {
      noResults.value = true;
    }
  } catch (err) {
    error.value = 'An error occurred while searching. Please try again.';
  } finally {
    searchLoading.value = false;
  }
};

const searchTemplate = async () => {
  if (!templateSearchQuery.value.trim()) return;
  await performSearch(() =>
    db.collection('summary-templates')
      .doc(templateSearchQuery.value.trim())
      .get()
  );
};

const searchUser = async () => {
  if (!userSearchQuery.value.trim()) return;
  searchLoading.value = true;
  error.value = null;
  noResults.value = false;
  userTemplates.value = []; // Reset previous results

  try {
    const query = userSearchQuery.value.trim();
    console.log('[searchUser] Query:', query);
    let userId;
    
    // If the query looks like an email, search in the "user" collection by email.
    if (isEmail(query)) {
      console.log('[searchUser] Detected email format. Searching by email:', query);
      const userSnapshot = await db.collection('user') // collection is "user"
        .where('email', '==', query.toLowerCase())
        .limit(1)
        .get();
      
      console.log('[searchUser] User snapshot size:', userSnapshot.size);
      if (userSnapshot.empty) {
        console.log('[searchUser] No user found with that email.');
        noResults.value = true;
        return;
      }
      
      // Extract the user document data
      const userDoc = userSnapshot.docs[0];
      const userData = userDoc.data();
      console.log('[searchUser] Found user data:', userData);
      
      // Use the userId field inside the document if it exists, otherwise fallback to the doc ID.
      userId = userData.userId ? userData.userId : userDoc.id;
      console.log('[searchUser] Using user id:', userId);
    } else {
      // Otherwise, assume the query is already a user ID.
      console.log('[searchUser] Query does not appear to be an email. Using as user ID:', query);
      userId = query;
    }
    
    // Now query the summary-templates collection for templates with this userId.
    console.log('[searchUser] Searching summary-templates for userId:', userId);
    const templateSnapshot = await db.collection('summary-templates')
      .where('userId', '==', userId)
      .get();
    
    console.log('[searchUser] Templates snapshot size:', templateSnapshot.size);
    if (templateSnapshot.empty) {
      console.log('[searchUser] No templates found for userId:', userId);
      noResults.value = true;
      return;
    }
    
    // Map the found documents to an array, including the template ID.
    userTemplates.value = templateSnapshot.docs.map(doc => {
      console.log('[searchUser] Found template:', doc.id, doc.data());
      return {
        ...doc.data(),
        templateId: doc.id
      };
    });
    console.log('[searchUser] Total templates found:', userTemplates.value.length);
  } catch (err) {
    console.error('[searchUser] Error searching user:', err);
    error.value = 'An error occurred while searching. Please try again.';
  } finally {
    searchLoading.value = false;
    console.log('[searchUser] Search finished.');
  }
};

// Format a Firestore timestamp
const formatDate = (timestamp) => {
  if (!timestamp) return 'Unknown';
  const date = new Date(timestamp.seconds * 1000);
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};

// Save the template header (general title)
const saveTemplateHeader = async () => {
  if (!selectedTemplate.value) {
    console.log("No template selected.");
    return;
  }
  headerLoading.value = true;
  try {
    const docRef = db.collection('summary-templates').doc(selectedTemplate.value.templateId);
    await docRef.update({
      templateHeader: selectedTemplate.value.templateHeader,
      updatedAt: new Date()
    });
    alert('Template header saved successfully!');
  } catch (err) {
    console.error("Error saving template header:", err);
    alert('Error saving template header.');
  } finally {
    headerLoading.value = false;
  }
};

// Save a single section to Firestore
const saveSection = async (section) => {
  if (!selectedTemplate.value) {
    console.log("No template selected.");
    return;
  }
  section.loading = true;
  try {
    const docRef = db.collection('summary-templates').doc(selectedTemplate.value.templateId);
    const sectionData = {
      order: section.order,
      style: section.style,
      dlevel: section.dlevel
    };

    if (section.name !== section.originalName) {
      // If the section name has changed, update with a new key and remove the old one.
      const updateData = {};
      updateData[`items.${section.name}`] = sectionData;
      updateData[`items.${section.originalName}`] = FieldValue.delete();
      await docRef.update({ ...updateData, updatedAt: new Date() });
      section.originalName = section.name;
    } else {
      // Otherwise, just update the section data.
      await docRef.update({
        [`items.${section.name}`]: sectionData,
        updatedAt: new Date()
      });
    }
    if (!selectedTemplate.value.items) selectedTemplate.value.items = {};
    selectedTemplate.value.items[section.name] = sectionData;
    alert(`Section "${section.name}" saved successfully!`);
  } catch (err) {
    console.error("Error saving section:", err);
    alert(`Error saving section "${section.name}".`);
  } finally {
    section.loading = false;
  }
};
</script>

<style scoped>
.template-details-card,
.user-templates-card {
  margin-top: 16px;
  padding: 16px;
  background-color: #1E1E1E !important;
}
.instruction-text {
  white-space: pre-wrap;
  background-color: #2D2D2D;
  padding: 12px;
  border-radius: 4px;
  font-size: 0.95em;
}
.section-detail {
  border-left: 3px solid #1976d2;
  padding-left: 16px;
}
.section-title-text {
  font-size: 5.25rem;
  font-weight: bold;
  letter-spacing: 0.5px;
}
</style>
