<template>
  <v-container>
    <TemplateSearch />
  </v-container>
</template>

<script>
import TemplateSearch from '@/components/TemplateSearch.vue';

export default {
  name: 'TemplatesView',
  components: {
    TemplateSearch
  }
};
</script>
