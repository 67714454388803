import { createRouter, createWebHistory } from 'vue-router'
import ScraperView from '@/views/ScraperView.vue'
import LeadsView from '@/views/LeadsView.vue'
import EmailsView from '@/views/EmailsView.vue'
import AuthView from '@/views/AuthView.vue'; // Import the new auth view
import PlaygroundView from '@/views/PlaygroundView.vue';
import ClinicsView from '@/views/ClinicsView.vue';
import UsersView from '@/views/UsersView.vue';
import TemplatesView from '@/views/TemplatesView.vue';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import getRedirectRoute from '@/functions/getRedirectRoute';

let authReady = false;

const authInitialized = new Promise(resolve => {
  onAuthStateChanged(getAuth(), (user) => {
    if (!authReady) {
      authReady = true;
      resolve(user);
    }
  });
});


const requireAuth = async (to, from, next) => {

  const user = await authInitialized;

  if (!user) {
    next({ name: 'auth' });
    return;
  }

  try {
    await getRedirectRoute(user);
    next();
  } catch (error) {
    next({ name: 'auth' });
  }
}


const routes = [
  {
    path: '/auth',
    name: 'auth',
    component: AuthView, // Add the auth view route
  },
  {
    path: '/',
    redirect: '/home', // Redirect root path to /home
  },
  {
    path: '/home',
    name: 'home',
    component: ScraperView,
    beforeEnter: requireAuth,
  },
  {
    path: '/leads',
    name: 'leads',
    component: LeadsView,
    beforeEnter: requireAuth
  },
  {
    path: '/emails',
    name: 'emails',
    component: EmailsView,
    beforeEnter: requireAuth
  },
  {
    path: '/playground',
    name: 'playground',
    component: PlaygroundView,
    beforeEnter: requireAuth
  },
  {
    path: '/clinics',
    name: 'clinics',
    component: ClinicsView,
    beforeEnter: requireAuth
  },
  {
    path: '/templates',
    name: 'templates',
    component: TemplatesView   // Make sure it's being used here
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
    beforeEnter: requireAuth
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
